<template>
  <div class="container">
    
<FormKitLazyProvider config-file="true">
<div class="main-container">
      <h3 v-if="labelH3">{{ labelH3 }}</h3>
      <FormKit
        id="myForm"
        #="{ disabled }"
        type="form"
        :actions="false"
        :incomplete-message="false"
        @submit="onSubmit"
      >
        <div class="user-input-form">
          <div class="name-email-group">
            <FormKit
              v-model="name"
              name="username"
              validation="required:trim | length:2"
              type="text"
              :placeholder="`${$t('forms.name')} *`"
              :validation-messages="{
                required: $t('forms.required_field'),
                length: $t('forms.length_min_field', {count: 2})
              }"
            />
            <FormKit
              v-model="email"
              name="email"
              validation="required:trim | email"
              type="email"
              :placeholder="`${$t('forms.email')} *`"
              :validation-messages="{
                required: $t('forms.required_field'),
                email: $t('forms.incorrect_email'),
                }"
            />
          </div>
          <FormKit
            v-model="message"
            validation="required:trim | length:6"
            type="textarea"
            :placeholder="$t('forms.your_message')"
            :style="{ height: '106px' }"
            :validation-messages="{
              required: $t('forms.required_field'),
              length: $t('forms.length_min_field', {count: 6})
            }"
          />
        </div>
        <template v-if="isUseRecaptcha">
          <div class="user-recaptcha">
            <RecaptchaCustom id="form-block" />
          </div>
        </template>
        <div class="user-submit-form">
          <FormKit
            :disabled="!accepted"
            type="submit"
            input-class="btn btn-primary"
            :label="$t('common.send')"
          />
          <FormKit
            v-model="accepted"
            validation="accepted"
            type="checkbox"
            decorator-icon="check"
            :validation-messages="{
              accepted: $t('forms.accept_message')
            }"
          >
            <template #label="{ id, classes }">
              <label
                :for="id"
                :class="classes.label"
              >
                <div
                  v-html="$t('common.i_agree_with_processing_policy', {
                    link: localePath('/privacy')
                  })"
                />
              </label>
            </template>
          </FormKit>
        </div>
      </FormKit>
    </div>
</FormKitLazyProvider>

  </div>
</template>

<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import { type ComputedRef, ref } from 'vue';
import { reset } from '@formkit/core'
import type { IBlockDefaultProps } from "#sitis/internal/models/common";
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { useLoadingStore } from '~/store/loading';

const RecaptchaCustom = defineAsyncComponent(() => import('~/components/common/RecaptchaCustom.vue'));

const props = defineProps<IBlockDefaultProps>();
const loadingStore = useLoadingStore();
const localePath = useLocalePath();

const {
  $api,
  $toast,
  $recaptcha
} = useNuxtApp();
const $i18n = useI18n();
const appConfigStore = useAppConfigStore();

const labelH3: ComputedRef<string> = computed(() => {
  return props?.values?.heading || '';
});

const isUseRecaptcha: ComputedRef<boolean> = computed(() => {
  return Boolean(String(appConfigStore.$state?.config?.useReCaptchaForForms || '0') === '1');
});

const message = ref('');
const name = ref('');
const email = ref('');
const accepted = ref(false);

const onSubmit = async () => {
  loadingStore.changeLoading(true);
  const token = await $recaptcha.getResponse().catch(() => '');
  const _res = await $api.agent.post('/form/send', {
    body: {
      message: message.value,
      name: name.value,
      email: email.value,
      nophone: 1,
      type: props.values?.type,
      'g-recaptcha-response': token
    }
  }).then((res) => {
    return res?._data;
  }).catch((err) => {
    return { error: err?.response?.data?.[0]?.message || $i18n.t('errors.error_send_form') };
  });
  if (_res?.error) {
    loadingStore.changeLoading(false);
    $toast.error(_res?.error);
    return
  }

  loadingStore.changeLoading(false);

  name.value = "";
  email.value = "";
  message.value = "";
  accepted.value = false;
  reset('myForm');

  $toast.success($i18n.t('common.success_send_application'));
};
</script>

<style scoped lang="scss">
.main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  background-color: hsla(var(--black)/0.05);
  border-radius: 10px;
  margin-top: 20px;

  .user-input-form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .name-email-group {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      gap: 10px;
    }

    .formkit-outer[data-type="textarea"] {
        flex-grow: 1;
    }

    .message-input {
        height: 106px;
    }
  }
  .user-recaptcha {
    margin-top: 20px;
  }
  .user-submit-form {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    .formkit-outer[data-type="checkbox"] {
        align-self: center
    }
  }
}
</style>
